.banner {
  &.size-full {
    position: relative;
    A:not([class]), DIV.banner__img {
      display: block; position: relative; overflow: hidden; height: 0; padding-bottom: 34%;
    }
    PICTURE {
      position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 100%; min-width: 100%; text-align: center;
      IMG { height: 100%; object-fit: cover; object-position: center; }
    }
    .banner__text {
      position: absolute; left: 0; right: 0; bottom: 0; top: 0; color: $color-text-light; background-color: transparentize($color01, .5); display: flex; flex-direction: column; justify-content: center; align-items: center;
      text-align: center;
      &-inner {
        padding: $gridunit;
        > *:first-child { margin: 0; }
      }

    }
    &.has-custom-height {
      > A { height: 100%; }
    }
  }
}
.banners {
  &.wrap {
    display: flex; flex-wrap: wrap;
    .frame-automaailm__section__limiter & { margin: 0 $gridunit-small/-1; }
    .banner {
      flex: 1; box-sizing: border-box;
      A:not([class]) { display: block; overflow: hidden; position: relative; }
      &.size-full {
        flex: 0 0 100%; width: 100%;
        &.compact {
          @include mobile {
            A { height: 200px; }
          }
        }
      }
    }
  }
  + .banners { margin-top: $gridunit !important; }
}

HTML .carousel {
  .control-pause,
  .control-page {
    &.current {
      background-color: $color-primary; color: $color-white;
      &:hover { color: $color-white; background-color: darken($color-primary,5%);}
    }
    &:hover { color: $color-primary; }
  }
}
.frame-automaailm .carousel {
  .control-page,
  .control-pause {
    &.current {
      background-color: $color-primary;
      &:hover { background-color: darken($color-primary,5%);}
    }
    &:hover { color: $color-primary; }
    &:focus {  }
  }
}
