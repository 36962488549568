@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";
@import "~@blueprintjs/core/src/common/react-transition";
@import "~@blueprintjs/core/src/components/popover/common";

@import "mixins";
@import "variables";

.frame-automaailm {
	flex: 1 0 auto; box-sizing: border-box; min-height: 100vh; min-height: -webkit-fill-available; display: flex; flex-direction: column;

	.userhub {
		flex: 0 0 auto; text-transform: uppercase; font-size: $font-size-smallest; font-weight: $bold;
		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; margin: 0 0 0 $gridunit-large - ($gridunit / 4); line-height: $gridunit;

		LI { margin-left: $gridunit / 4; flex: 0 0 auto; }

		.#{$ns}-popover-wrapper,
		.#{$ns}-popover-target { display: block; position: relative; }

		A {
			background-color: rgba($color-link, 0.05); text-decoration: none; display: flex; align-items: center; justify-content: center; position: relative; padding: 10px; color: currentColor;
			.icon {
				flex: 0 0 auto; display: block; width: 20px; height: 20px; overflow: hidden; margin: 0 10px 0 0; opacity: 1;
				img { width: 100%; height: auto; float: left; }
				@include mobile { width: 14px; height: 14px; }
			}
			.amount {
				margin-left: 7px; color: $color-danger; font-weight: $bold; font-size: 14px;
				@include not-desktop { color: $color-warning; }
			}
			.products { display: none; }

			&:active,
			&:focus,
			&:hover {
				outline: none;
				.icon { opacity: 1; }
			}

			&.desktop {
				position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 2;
				@include not-desktop { display: none; }
				&:hover + * {
					color: $color-text-dark;
					.icon { opacity: 1; }
				}
			}
			@include mobile {
				background-color: transparent;
			}
		}

		UL { display: none; }

		@include mobile {
			font-weight: 400; text-transform: none; margin-left: 0;
			LI { margin: 0; }
			A {
				padding: 10px; position: relative;
				.products {
					position: absolute; right: 0; top: 0;
					background-color: $color05; color: $color-white;
					font-size: 9px; line-height: 16px; padding: 0 3px; min-width: 16px; text-align: center; border-radius: 8px; box-sizing: border-box;
					display: flex; align-items: center; justify-content: center;
				}
			}
		}
	}

	&__pre-header {
		flex: 0 0 auto; box-sizing: border-box; background-color: $color02; color: $color-white; padding: 0 $gridunit / 2  0 $gridunit; font-size: 10px;
		display: flex; flex-direction: row; flex-wrap: nowrap;
		A { color: inherit; }

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		}

		&__languages {
			display: flex; flex-direction: row; flex-wrap: nowrap;
			> A { margin: 0 $gridunit / 2 0 0; padding: 0; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; }
			IMG { width: 20px; height: auto; margin: auto 0; flex: 0 0 auto; }
		}

		@include mobile {
			.link-search,
			.link-cart { display: none; }
		}
		@include not-mobile {
			position: sticky; z-index: 11; top: 0;
			.link-search { display: none; }
		}
		@include desktop { display: none; }
	}

	&__header {
		position: sticky; z-index: 10; top: 0;
		display: flex; flex-direction: column; flex-wrap: nowrap; flex: 0 0 auto; box-sizing: border-box; background-color: $color-white;
		padding: 0 $spacer-desktop;
		&:after { content: ""; position: absolute; left: 0; right: 0; top: 100%; height: $gridunit-large; background: linear-gradient(180deg, rgba($color-black, 0.05), rgba($color-black, 0)); pointer-events: none; }

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box;
			@include mobile { display: flex; flex-direction: row; align-items: stretch; justify-content: space-between; }
			@include not-mobile {
				display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: stretch; padding-top: $gridunit;
				& + & { padding-top: 0; }
			}
			@include not-desktop {
				flex: 1 0 auto; width: auto; padding: 0; margin: 0;
				& + & { flex: 0 0 auto; }
			}
		}

		&__languages {
			display: flex; flex-direction: row; flex-wrap: nowrap;
			> A { margin: 0 0 0 $gridunit / 2; padding: 0; display: flex; align-items: center; justify-content: center; }
			IMG { height: 14px; margin: auto 0; flex: 0 0 auto; box-shadow: 0 0 0 2px rgba($color-link, 0.1); }
			@include not-desktop { display: none; }
		}

		.logo {
			margin: 0 $gridunit-large 0 0; display: flex; align-items: center; justify-content: center;
			img { width: auto; height: 40px; display: block; }

			@include mobile {
				padding: 10px 0;
				IMG { height: 20px; }
			}
			@include tablet { margin-right: $gridunit; }
		}

		.userhub {
			A {
				.icon {
					float: left; width: 20px; height: 20px;
					IMG { transform: translate(0, -50%); }
				}
				@include mobile {
					.icon { margin: 0; }
					.amount,
					.label { display: none; }
				}
			}
			@include tablet { display: none; }
		}

		.search__results {
			position: absolute; left: 0; right: 0; top: 100%; z-index: 100;
			color: $color-text-dark; transition: all $uispeed $uieasing 0ms; opacity: 0;
			background-color: $color-white; border: solid $color-gray-light; border-width: 0 1px 1px 1px; border-radius: 0; font-size: 13px;
			max-height: 350px; overflow: hidden; display: flex; flex-direction: column;

			&.open {
				opacity: 1;
				& .search__results__list { transform: translate(0, 0); }
			}
			&__actions { border-top: 2px solid $color-gray-lighter; text-align: center; padding: $gridunit; }
		}

		@include not-desktop {
			padding: $gridunit; flex-direction: row-reverse; flex-wrap: nowrap;
			.link-account,
			.link-contact { display: none; }
		}
		@include tablet { top: 40px; }
		@include mobile {
			padding: 5px 10px;
			.search { display: none; }
		}
		@include not-mobile {
			.link-search { display: none; }
		}
	}

	&__submenu {
		background-color: $color-gray-lightest; width: 100%; overflow: hidden; flex: 0 0 auto; border-bottom: 1px solid $color-gray-lighter;
		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; overflow: hidden;
			UL.categories {
				flex: 0 0 auto; margin: 0; box-sizing: border-box; text-align: center;
				overflow: hidden; overflow-x: auto; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto; padding: 15px 0; white-space: nowrap;
				> LI { width: 100px; text-align: center; font-size: 11px; line-height: 14px; box-sizing: border-box; padding: 0 10px; display: inline-block; vertical-align: top; }
				A {
					color: $color-gray-dark; display: block; white-space: normal;
					.image { display: block; padding: 55% 0 0 0; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%; margin-bottom: 5px; }
					.label { display: block; }
					.cat-label { display: block; margin: 0; vertical-align: 0; }
					&:hover { text-decoration: none; color: $color-link; }
				}
			}
		}
	}

	&__body {
		flex: 1 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; @include defaultPadding;
		&__limiter { width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; flex: 0 1 auto; }

		&.gray { background-color: $color-gray-lightest; }
		&.blue {
			background-color: $color02; color: $color-white;
			A { color: currentColor; text-decoration: underline; }
			.form-list {
				INPUT,
				SELECT,
				TEXTAREA {
					background-color: $color-white; color: $color-text-dark; box-shadow: inset 0 0 0 1px $color-white;
					&:focus { box-shadow: inset 0 0 0 1px $color01; }
				}
				INPUT[type="radio"] + LABEL:before,
				INPUT[type="checkbox"] + LABEL:before { background-color: $color-white; }
			}
			.form-buttons__button {
				background-color: $color01;
				&:active,
				&:focus,
				&:hover { background-color: $color01; box-shadow: 0 0 0 3px rgba($color01, 0.3); }
			}
		}
	}

	&__footer {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box; background-color: $color-white;
		&__limiter { width: 100%; max-width: $content-maxwidth; margin: auto; }

		H1, H2, H3, H4, H5, H6 { font-weight: $bold; font-size: 16px; margin: $gridunit / 2 0 0 0; }
		A {
			&:hover { text-decoration: none; color: $color-link-hover; }
		}
		P {
			margin: 10px 0 0 0;
			+ P { margin: 3px 0 0 0; }
			&.text-center { text-align: center; margin: $gridunit 0 0 0; font-size: 12px; }
		}

		.row {
			display: flex; flex-direction: row; flex-wrap: wrap; margin: 0 -6px;
			@include defaultPaddingTop(8px);
			.column {
				flex: 0 0 25%; overflow: hidden; padding: 12px 6px 0 6px; display: flex; flex-direction: column; box-sizing: border-box;
				@include not-desktop {
					flex: 0 0 50%;
				}
				@include mobile {
					flex: 0 0 100%;
				}
			}
		}

		.group {
			flex: 1 0 auto; text-align: center;
			background-color: $color-gray-lightest;
			padding: 0 $gridunit $gridunit $gridunit;
			&__image {
				margin: $gridunit 0 0 0; display: flex; align-items: center; justify-content: center;
				img, svg { width: auto; height: 50px; }
			}
			&__text { }
		}
		A.group {
			color: inherit;
			H1, H2, H3, H4 { color: currentColor; }
			&:hover {
				box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); background-color: rgba($color-white, 0.5);
				H1, H2, H3, H4 { color: $color-link; }
			}
		}

		UL.nav {
			padding: 0; margin: 10px 0 0 0;
			> LI {
				margin: 0;
				+ LI { margin: 3px 0 0 0; }
			}
			A {
				&:hover { color: $color-link-hover; }
			}
		}

		UL.links-secondary {
			margin: $gridunit $gridunit / -2 0 $gridunit / -2; text-align: center; color: $color-gray-dark;
			display: flex; flex-direction: column;
			> LI {
				margin: $gridunit / 2 $gridunit / 2 0 $gridunit / 2;
			}
			@include not-mobile {
				flex-direction: row; align-items: center; justify-content: center;
			}
			A {
				color: currentColor;
				&:hover { color: $color-link; }
			}
		}
	}

	&__pre-cart {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box; background-color: $color-gray-lightest;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include mobile { text-align: center; }
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }
		}

		&__image {
			position: relative; @include defaultMargin();
			@include not-mobile { margin-right: $gridunit; flex: 0 0 110px; }
			DIV {
				padding: 66% 0 0 0; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain;
				@include mobile { height: 100px; padding: 0; }
			}
		}

		&__product {
			flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; @include defaultMargin();
		}

		&__title {
			font-weight: $bold; font-size: $font-size-medium;
		}

		&__message {
			color: $color-success;
		}

		&__actions {
			@include defaultMargin();
			@include not-mobile { flex: 0 0 150px; margin-left: $gridunit; }
			.cart-buttons {
				margin: 0;
				> LI:first-child { margin-top: 0; }
			}
		}

		&__warning {
			@include defaultMargin(); font-size: $font-size-small; line-height: 1.4;
			background-color: $color-gray-lighter; padding: $gridunit;
			@include not-mobile { flex: 1 1 320px; margin-left: $gridunit; }
		}
	}

	&__copyright {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box;
		background-color: $color-black; color: $color-white; font-size: 13px;

		H1, H2, H3, H4, H5, H6 { color: $color-white; }
		A { color: inherit; }

		.logo {
			flex: 0 0 auto;
			IMG { width: 150px; height: auto; }
		}

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: space-between; }
			@include mobile { text-align: center; }
		}

	}

	&__illustration {
		background: 50% 50% no-repeat; background-size: cover; background-color: $color-gray-lightest; padding-top: 33.708%; @include defaultMargin();
	}

	&__page-header {
		flex: 0 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; @include defaultPadding; background-color: $color-gray-lightest;
		@include not-mobile {
			&.white { background-color: $color-white; }
		}
		&__limiter { width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; flex: 0 1 auto; }

		.breadcrumb {
			font-size: $font-size-smaller; line-height: 1.4; display: flex; flex-direction: row; justify-content: space-between; color: $color-gray-dark; white-space: nowrap;
			.back {
				margin: 5px 0 0 0; position: relative;
				&:after { content: ""; position: absolute; right: 100%; top: 50%; width: 0.5em; height: 0.5em; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform: translate(-150%, -50%) scaleX(0.85) rotate(-135deg) scale(0.8); transform-origin: 50% 50%; }
				@include mobile { display: none; }
			}
			.path {
				display: flex; flex-direction: row; flex-wrap: wrap;
				> LI {
					margin: 5px 10px 0 0;
					&:not(:last-child) {
						A { margin-right: 10px; }
						&:after { content: "/"; opacity: 0.6; }
					}
				}
			}
			A {
				color: inherit; text-decoration: none;
				&:hover { color: $color-link-hover; }
			}
			@include mobile {
				font-size: $font-size-smallest;
				.path > LI {
					margin-right: 5px;
					&:not(:last-child) {
						A { margin-right: 5px; }
					}
				}
			}
		}

		@include mobile {
			border-bottom: 1px solid $color-gray-lighter;
		}
		@include not-mobile {
			padding-bottom: 0 !important;
		}
	}

	@include mobile {
		&__pre-header .search { display: none; }
	}
}

.global-frame-automaailm {
	padding: 0; margin: 0; background-color: $color-white;
	BODY { padding: 0; margin: 0; }
}

// Overlay placement

.global-frame-automaailm {
	@include not-desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { top: 0; }
	}
	@include desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { left: 0; }
		.#{$ns}-overlay .overlay-focusview-holder { padding-left: 0; }
	}
}

// Searchfield

.search {
	box-shadow: none; flex: 1 1 auto; display: block; position: relative;
	&__container {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 1 1 auto; align-items: center; position: relative;
		border-radius: 0; overflow: hidden; background-color: $color-white; color: $color-black; box-shadow: inset 0 0 0 1px $color-gray-medium;
	}
	.label { display: none; }
	INPUT:not([type="submit"]) {
		padding: 10px 0 10px 10px; border: 0 none; outline: none; box-shadow: none; width: 100%; overflow: hidden; border-radius: 0; flex: 1 1 auto; box-sizing: border-box; background-color: transparent;
		color: currentColor; height: 40px; line-height: 20px; font-size: 14px;
	}
	BUTTON,
	INPUT[type="submit"] {
		padding: 0 15px; margin: 0; line-height: 30px; width: 30px; height: 30px; flex: 0 0 auto; border: none; box-shadow: none; background-color: rgba($color-black, 0.5); color: inherit; font-size: 12px !important; border-radius: 0;
		opacity: 0; cursor: pointer;
	}
	@include mobile {
		border-radius: 0;
		INPUT:not([type="submit"]) { font-size: 16px; height: 40px; line-height: 40px; }
		BUTTON,
		INPUT[type="submit"] { line-height: 30px; height: 30px; margin: 5px; border-radius: $base-radius; }
	}

	.icon {
		flex: 0 0 auto; display: block; width: 14px; height: 14px; margin: 0 10px 0 0; position: relative; overflow: hidden;
		img { width: 100%; height: auto; float: left; transform: translate(0, 0); }
		@include mobile { margin: 0; }
	}
	SPAN.icon {
		overflow: visible; position: absolute; right: 0; top: 50%; transform: translate(0, -50%); pointer-events: none;
		&:before { content: ""; position: absolute; left: 50%; top: 50%; background-color: $color05; width: 70px; height: 70px; transform: translate(-50%, -50%) scaleX(0.6) rotate(45deg); transform-origin: 50% 50%; }
	}

	BUTTON.icon { display: none; }

	&__results {
		&__list {
			flex: 0 1 100%; position: relative; z-index: 11;
			padding: 0; transition: all $uispeed $uieasing 0ms;
			@include desktop {
				transform: translate(0, -20px); @include overflow();
			}
			> LI {
				&:not(:last-child) { border-bottom: 1px solid $color-gray-lighter; }
			}
		}
		&__result {
			A {
				display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: flex-start; text-decoration: none; overflow: hidden; white-space: nowrap; padding: 10px; position: relative; line-height: 18px;
				&.active,
				&:hover {
					text-decoration: none;
					.name { color: $color-link; }
				}
				&.active { background-color: rgba($color-link, 0.05); }
			}
			.image { flex: 0 0 40px; width: 40px; height: 40px; margin-right: 10px; overflow: hidden; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%; }
			.text {
				flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis;
			}
			.name { font-weight: $medium; flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
			.price { color: $color-gray-dark; }
		}
	}

	&__backdrop {
		position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 2; background-color: rgba($color-gray-darker, 0.48); transition: all $uispeed $uieasing 0ms; opacity: 0; pointer-events: none;
		&.open { pointer-events: auto; opacity: 1; }
	}
}

.search-mobile {
	& &-heading {
		background-color: $color02; color: $color-white;
		.search {
			flex: 1 0 auto; display: flex; flex-direction: column; justify-content: center; color: $color-white;
			INPUT:not([type="submit"]) {
				color: inherit; font-size: 16px; padding-left: 25px;
				&::placeholder { color: rgba($color-white, 0.5); }
			}
			INPUT[type="submit"] { display: none; }
			SPAN.icon {
				left: 15px; right: auto;
				&:before { display: none; }
			}
			BUTTON.icon {
				opacity: 1; display: block; padding: 0; margin: 0; border-radius: 0; background-color: transparent; position: relative; overflow: visible;
				&:before { content: ""; position: absolute; left: -15px; top: -15px; right: -15px; bottom: -15px; }
			}
			&__container { background-color: transparent; color: inherit; box-shadow: none; padding: 5px 20px; }
		}
	}
	& &-content {
		padding: 0;
		.search__results__actions { border-top: 2px solid $color-gray-lighter; text-align: center; padding: $gridunit; }
	}
}

.overlay-search {
	&-content {
		background-color: $color-white;
	}
}

// Site menu

.site-menu {
	flex: 0 0 auto; display: flex; position: relative;

	@include desktop {
		flex: 1 1 auto; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		LI { flex: 0 0 auto; }
	}

	@include not-desktop { flex-direction: row; align-items: center; }

	.menutoggler {
		font-size: 0; color: rgba($color-black, 0); padding: 10px; margin-right: 10px; box-sizing: border-box; position: relative; display: flex; align-items: center; justify-content: center;
		@include desktop { display: none; }
		@include tablet { margin-right: 20px; height: 40px; background-color: rgba($color02, 0.1); }
		span {
			display: block; width: 20px; height: 2px; background-color: $color-link; position: relative;
			&:before, &:after { content: ""; height: 2px; position: absolute; left: 0; right: 0; background-color: $color-link; }
			&:before { top: -6px; }
			&:after { bottom: -6px; }
		}
		&:hover {
			opacity: 1;
		}
	}

	NAV,
	NAV > UL,
	NAV > UL > LI { flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; }
	NAV, NAV > UL { flex: 1 0 auto; }
	NAV {
		font-size: 14px; padding: 0; flex: 0 0 auto;
		@include not-desktop { display: none !important; }
		UL { padding: 0; margin: 0; }
		LI {
			padding: 0; margin: 0 !important;
			&:before { display: none; }
		}
		> UL {
			list-style: none;
			> LI {
				padding: 0 0 0 $gridunit-large;
				&:first-child { padding-left: 0; }
				> A { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; }
				@include not-desktop-wide { padding:  0 0 0 $gridunit; }
			}
			A {
				display: block; padding: $gridunit 0; position: relative; font-weight: $bold;
				.label {}
				.dropper,
				.dropper span {
					position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 1; font-size: 0; color: rgba($color-black, 0);
				}
				&:hover {
					color: $color05; text-decoration: none;
					.#{$ns}-popover-target {
						&:before { opacity: 1; }
					}
				}
				&.#{$ns}-menu-item {
					padding-right: 14px;
					&:before { content: ""; position: absolute; right: 0; top: 50%; transform: translate(0, -50%); width: 0; height: 0; border-style: solid; border-width: 6px 4px 0 4px; border-color: currentColor transparent transparent transparent; opacity: 0.2; }
				}
			}
			IMG { width: 100px; }
			UL { display: none; }
		}
	}

	.#{$ns}-popover-target { display: block; position: relative; }

	&-portal {
		.#{$ns}-transition-container { z-index: $modalZ + $pt-z-index-overlay; }
	}

	&-popover {
		display: block; z-index: $modalZ + $pt-z-index-overlay;

		.#{$ns}-popover-content {
			max-width: 100%; padding: 0;
			@include clearfix;
			@include mobile { max-width: calc(100vw - 40px); }
			&:focus { outline: none; }
			*:focus { outline: none; }
		}
		.#{$ns}-transition-container {
			display: flex; z-index: $modalZ + $pt-z-index-overlay; padding-bottom: $gridunit / 2;
			&:focus { outline: none; }
			&.#{$ns}-popover-leave .#{$ns}-popover-content { pointer-events: none; }
			&[data-x-out-of-boundaries] { display: none; }
		}

		&:focus { outline: none; }

		.#{$ns}-overlay-inline { display: inline; overflow: visible; }
		.#{$ns}-popover {
			&-target { position: relative; display: block; }
		}
		.#{$ns}-submenu {
			.#{$ns}-popover {
				padding-top: 5px;
			}
		}

		.#{$ns}-menu {
			background-color: $color-white; border-radius: 0; font-size: 13px; box-shadow: $shadow01;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			max-height: 200px;
			width: auto;

			> LI {
				border-top: 1px solid $color-gray-lighter; min-width: 200px;
				&:first-child { border-top: none; }
			}
			&-item {
				background-color: transparent; padding: 10px 20px; cursor: pointer; font-weight: 400;
				&:active,
				&:focus,
				&:hover { text-decoration: none; background-color: rgba($color-link-hover, 0.05); color: $color-link-hover; }
				&.important { font-weight: $bold; color: $color-link; }
			}
		}
	}

	&-mobile {
		& &-heading {
			&.right { flex-direction: row-reverse; }
			background-color: $color-white; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; padding: $gridunit;
			BUTTON {
				cursor: pointer; border: none; background-color: transparent; padding: 0; width: $gridunit; height: $gridunit; position: relative; color: currentColor;
				svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
				path { fill: currentColor; }
			}
			.logo { width: auto; height: $mobile-header-height - $gridunit; margin: $gridunit / 2 $gridunit; }
		}
		& &-content {
			padding: 0 20px 20px 20px;
			UL {
				padding: 1px 0 0 0; margin: 0; list-style: none; position: relative; box-sizing: border-box;
				&:before { content: ""; width: auto; height: 1px; opacity: 0.1; background-color: currentColor; position: absolute; left: 0; right: 0; bottom: auto; top: 0; transform: none; }
				LI {
					padding: 0 0 1px 0; margin: 0; position: relative;
					&:before { content: ""; width: auto; height: 1px; opacity: 0.1; background-color: currentColor; position: absolute; left: 0; right: 0; bottom: 0; top: auto; transform: none; }
				}
				A { display: block; padding: 10px 0; color: inherit; text-decoration: none; }
				UL {
					padding: 0 0 0 20px;
					> LI:last-child {
						padding: 0;
						&:before { display: none; }
					}
				}

				&.grouped {
					padding-bottom: 20px;
					UL { padding: 0; }
					.group-heading { font-weight: $bold; padding: 30px 0 10px 0; opacity: 0.5; }
				}
			}
		}
	}

	&__drop {
		visibility: hidden; position: absolute; top: 100%; left: 0; right: 0; background-color: $color-white; color: $color-text-dark; opacity: 0; pointer-events: none; transition: all $uispeed $uieasing 0ms; box-shadow: $shadow01; @include defaultPadding;
		line-height: 24px; margin-top: -10px;
		@include not-desktop { display: none; }
		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; position: relative; transition: all $uispeed $uieasing 0ms; transform: translate(0, -20px);
		}

		&.open { opacity: 1; pointer-events: auto; visibility: visible; }
		&.open &__limiter { transform: translate(0, 0); }


		UL.grouped {
			display: flex; flex-direction: row; flex-wrap: wrap;
			> LI {
				flex: 0 0 20%; box-sizing: border-box; overflow: hidden;
				border-left: 1px solid $color-gray-lighter;
				padding-left: $gridunit - 1;
				@include defaultMargin;

				&.wide-2 {
					flex: 0 0 40%;
					UL {
						/*
						display: flex; flex-direction: row; flex-wrap: wrap;
						> LI { flex: 0 0 50%; box-sizing: border-box; margin: 0; padding: 0; }
						 */
						column-count: 2;
					}
				}
				&.wide-3 {
					flex: 0 0 60%;
					UL {
						/*
						display: flex; flex-direction: row; flex-wrap: wrap;
						> LI { flex: 0 0 33.333%; box-sizing: border-box; margin: 0; padding: 0; }
						 */
						column-count: 3;
					}
				}
			}
			UL {
				padding: 0; margin: 5px 0 0 0; list-style: none;
				> LI {
					padding: 0; margin: 0 25px 0 0; overflow: hidden;
					&:before { display: none; }
				}
				A {
					float: left; display: inline-block; padding: 3px 0; color: $color-gray-darker; white-space: nowrap; text-overflow: ellipsis; max-width: 100%; overflow: hidden;
					&:hover { text-decoration: underline; color: $color-link; }
					&.all { color: $color-link; }
				}
				&:first-child {
					margin: 0;
					A { color: $color-link; font-weight: bold; }
				}
			}
			> LI {
				&:first-child:last-child {
					flex: 0 0 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: flex-start;
					justify-content: flex-start;
					padding: 0;
					border: none;
					UL {
						display: flex; flex-direction: column; flex-wrap: wrap; align-items: flex-start; justify-content: flex-start;
						max-height: 200px;
						> LI {
							flex: 0 0 auto;
							width: 240px;
							border-left: 1px solid $color-gray-lighter;
							padding-left: $gridunit - 1;
						}
					}
				}
			}
		}

		.group-heading { font-weight: bold; }
	}

}

.overlay-mainmenu {
	&-content { background-color: $color-white; color: $color-black; }
}

// Category tree

.category-tree {
	flex: 0 0 auto; @include defaultMargin();

	@include not-mobile {
		background-color: $color-gray-light; padding: 1px 0;
	}

	.menutoggler {
		display: none; margin: $gridunit * -1 $gridunit * -1 0 $gridunit * -1; padding: $gridunit / 2 $gridunit; color: inherit; background-color: $color-gray-lightest; border-bottom: 1px solid $color-gray-lighter;
		font-size: 12px; text-transform: uppercase; font-weight: $bold;
		&:active,
		&:focus,
		&:hover { text-decoration: none; color: $color-link; }

		@include mobile {
			display: flex; align-items: center; justify-content: flex-start; background-color: $color-white;
		}

		.icon {
			flex: 0 0 auto; display: block; width: 16px; height: 16px; overflow: hidden; margin: 0 10px 0 0;
			IMG { width: 100%; height: auto; float: left; transform: translate(0, -50%); }
		}
		.label {
			flex: 1 1 auto;
		}
	}

	&__group {
		overflow: hidden; position: relative; user-select: none; line-height: 20px; background-color: $color-gray-lightest;
		& + & { margin-top: 1px; }
		@include mobile {
			display: none;
			& + & { margin-top: 1px; }
		}

		INPUT[type="checkbox"] {
			position: absolute; right: 100%; bottom: 100%;

			+ LABEL,
			+ * LABEL {
				cursor: pointer;
				&:first-child {
					color: $color-text-dark;
					&:active,
					&:focus,
					&:hover { color: $color-link; }
				}
				&:before { content: "+"; transition: all $uispeed $uieasing 0ms; font-weight: 400; display: block; width: 20px; text-align: center; line-height: 20px; position: absolute; right: 0; top: 50%; transform: translate(0, -50%); transform-origin: 50% 50%; z-index: 2; }
				&:after { content: ""; position: absolute; right: 0; top: 50%; height: 20px; width: 20px; border: 1px solid rgba($color-black, 0.1); box-sizing: border-box; background-color: $color-white; z-index: 1; transform: translate(0, -50%); }
				&:active,
				&:focus,
				&:hover { color: $color-link; }
			}

			&:active,
			&:focus,
			&:hover {
				+ LABEL,
				+ * LABEL {
					&,
					&:first-child { color: $color-link; }
				}
			}

			~ UL {
				display: none;
			}
		}

		LABEL {
			display: block; background-color: $color-gray-lightest; z-index: 2; position: relative; font-weight: $bold; box-sizing: border-box; line-height: $gridunit;
			margin: 0; padding: $gridunit / 2 30px $gridunit / 2 0; text-overflow: ellipsis; overflow: hidden; width: 100%;
		}

		UL { background-color: $color-gray-lightest; }

		INPUT[type="checkbox"] + * + * { transform: translateY(-100px); pointer-events: none; position: absolute; left: 0; top: 100%; width: 100%; z-index: -1; margin: 0; transition: all $uispeed $uieasing 0ms; display: block; }
		INPUT[type="checkbox"]:checked ~ LABEL:before,
		INPUT[type="checkbox"]:checked + * LABEL:before { transform: translate(0, -50%) rotate(45deg); }
		INPUT[type="checkbox"]:checked + LABEL ~ *,
		INPUT[type="checkbox"]:checked + * ~ *, { display: block; transform: none; pointer-events: auto; position: static; z-index: 0; }

		A {
			display: block; color: inherit; padding: $gridunit / 2 $gridunit;
			&:active,
			&:focus,
			&:hover { text-decoration: none; color: $color-link; }
			&.active {
				color: $color-link;
				+ UL { display: block; }
			}
			.count { opacity: 0.3; }
		}

		.item-row {
			display: flex; flex-direction: row; flex-wrap: nowrap; background-color: $color-gray-lightest; position: relative; z-index: 1;
			A { flex: 1 1 auto; padding: $gridunit / 2 $gridunit; display: block; }
			LABEL {
				flex: 0 0 auto; padding: $gridunit / 2 0; width: 30px;
				&:first-child { width: auto; flex: 1 1 auto; padding: $gridunit / 2 $gridunit; display: block; }
			}
		}

		UL:not([class]) {
			padding: 0; margin: 0; box-sizing: border-box; overflow: hidden;
			LI {
				padding: 0; margin: 0; overflow: hidden; border-top: 1px solid $color-gray-lighter;
				&:before { display: none; }
				LABEL { font-weight: 400; }
			}
			LI LI {
				font-size: $font-size-small;
				LABEL:before { opacity: 0.5; }
			}
			LI LI LI { font-size: $font-size-smaller; }

			LI A,
			LI LABEL:first-child { padding-left: $gridunit; }
			LI LI A,
			LI LI LABEL:first-child { padding-left: $gridunit * 1.5; }
			LI LI LI A,
			LI LI LI LABEL:first-child { padding-left: $gridunit * 2; }
			LI LI LI LI A,
			LI LI LI LI LABEL:first-child { padding-left: $gridunit * 2.5; }
			LI LI LI LI LI A,
			LI LI LI LI LI LABEL:first-child { padding-left: $gridunit * 3; }
			LI LI LI LI LI LI A,
			LI LI LI LI LI LI LABEL:first-child { padding-left: $gridunit * 3.5; }
		}
	}

	&__mobile {
		& &__heading {
			&.right { flex-direction: row-reverse; }
			background-color: $color-white; color: $color-text-dark; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; padding: $gridunit;
			BUTTON {
				cursor: pointer; border: none; background-color: transparent; padding: 0; width: $gridunit; height: $gridunit; position: relative; color: currentColor;
				svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
				path { fill: currentColor; }
			}
			.logo { width: auto; height: $mobile-header-height - $gridunit; margin: $gridunit / 2 $gridunit; }
		}
		& &__content {
			padding: $gridunit; background-color: $color-gray-lightest;
		}
	}
}

.overlay-category-tree {
	&-content {
		background-color: $color-gray-light; color: $color-text-dark;
		.category-tree {
			margin: 0; background-color: $color-gray-light; padding: 1px 0;
			.menutoggler { display: none; }
			&__group { display: block; }
		}
	}
}
