@import "mixins";
@import "variables";
@import "~@blueprintjs/core/src/common/variables";

// Promo box

.box-promo {
	background-color: $color-gray-lighter; background-size: cover; background-position: 50% 0; background-repeat: no-repeat; color: $color-text-dark;
	position: relative; z-index: 0; text-align: center; font-size: 17px; line-height: 1.5; box-sizing: border-box; min-height: 500px;
	flex: 1 0 auto; display: flex; flex-direction: column;
	&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: $shadow04; z-index: 100; pointer-events: none; }

	&.small {
		min-height: 300px;
		@include mobile {
			min-height: 600px;
		}
	}
	&.standalone {
		@include defaultMargin(); border-radius: $base-radius;
	}

	H2, H3, H4, H5, H6 { font-weight: $medium; margin: 0; color: inherit; font-size: 40px; line-height: 50px; }
	SUP { font-size: 30%; vertical-align: 0; transform: translate(0, -20%); position: absolute; }

	.pre-heading { margin: 0; font-size: 18px; font-weight: $medium; }
	.has-image { color: rgba(0, 0, 0, 0) !important; background-size: contain; background-position: 50% 50%; background-repeat: no-repeat; }

	@include mobile {
		min-height: 0;
		H2, H3, H4, H5, H6 { font-size: 36px; line-height: 42px; }
		.pre-heading { font-size: 18px; }
	}

	UL:not([class]) {
		padding: 0; margin: $gridunit 0 0 0;
		> LI {
			padding: 0; margin: 0;
			&:before { display: none; }
			+ LI {
				padding-top: 20px;
				&:before {
					display: block; width: 10px; height: 1px; left: 50%; top: 10px; transform: translate(-50%, 0); opacity: 0.2;
				}
			}
		}
	}

	&__text {
		flex: 0 0 auto; padding: 50px;
		@include mobile {
			padding: $gridunit;
		}
	}
	&__image {
		flex: 1 1 auto; margin: 0 50px 50px 50px;
		@include mobile {
			margin: 0 $gridunit $gridunit $gridunit;
		}
		background-size: contain; background-position: 50% 50%; background-repeat: no-repeat;
		&.no-margin { margin: 0 !important; }
	}

	&__actions {
		text-align: center; display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;
		&.vertical { flex-direction: column; }
		A {
			padding: 8px 16px; font-size: 12px; line-height: 20px; position: relative; z-index: 0; text-transform: uppercase; font-weight: $bold;
			transition: all $uispeed ease-in-out 0ms;
			&:not(.primary) {
				&:after { content: ""; position: absolute; left: 100%; top: 50%; width: 0.5em; height: 0.5em; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform: translate(-150%, -50%) scaleX(0.85) rotate(45deg) scale(0.8); transform-origin: 50% 50%; }
			}
			&.primary {
				background-color: $color-link; color: $color-white !important;
				&:active,
				&:focus,
				&:hover {
					opacity: 0.8;
					background-color: $color-link-hover; text-decoration: none; box-shadow: 0 0 0 3px rgba($color-link-hover, 0.5);
				}
			}
		}
	}

	// Wide size

	@at-root .layout-grid__container.wide & {
		@include not-mobile {
			flex-direction: row; flex-wrap: nowrap;
			&__text { flex: 0 0 450px; align-self: center; }
			&__image { flex: 1 1 auto; margin: 50px 50px 50px 0; }

			&.reversed { flex-direction: row-reverse; }
			&.reversed .box-promo__image { margin: 50px 0 50px 50px; }
		}
	}

	// On gray background

	@at-root .gray & {
		background-color: $color-white;
	}

	// Alignment

	&.align-horizontal-left {
		text-align: left;
	}
	&.align-vertical-center {
		align-items: center; justify-content: center;
	}

	// Text themes

	&.text-white {
		color: $color-white;
		A { color: #2997ff; }
	}
	&.text-white &__actions {
		A {
			color: $color07;
			&.primary { background-color: $color07; }
		}
	}

	// Action themes

	&.actions-white &__actions {
		A {
			color: $color-white;
			&.primary { background-color: $color-white; color: $color07 !important; }
		}
	}
}

// Product box

.box-product {
	background-color: $color-white; background-size: cover; background-position: 50% 0; background-repeat: no-repeat; box-shadow: $shadow04;
	position: relative; z-index: 0; box-sizing: border-box;
	flex: 1 0 auto; display: flex; flex-direction: column; padding: $gridunit;

	&:active,
	&:focus,
	&:hover {
		box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	}
	&:active,
	&:focus,
	&:hover &__title { color: $color-link-hover; }

	@include mobile {
		box-shadow: none; border: solid $color-gray-light; border-width: 0 0 1px 1px; padding: $gridunit-large / 2;
		&:hover {
			box-shadow: none;
		}
	}

	&__link { position: absolute; left: 0; right: 0; top: 0; bottom: 0; color: rgba(0, 0, 0, 0); z-index: 1; }

	&__image {
		position: relative; padding: 70% 0 0 0;
		span:not(.discount) { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain; }
		@include mobile {
			padding-top: 50%;
		}
		.discount {
			position: absolute; right: 0; top: 0;
			font-size: 10px; line-height: 16px; background-color: $color01; color: $color-white; padding: 0 4px; margin: 0 0 0 10px; user-select: none; vertical-align: 20%;
			&.real { background-color: $color05; }
		}
	}

	&__content { flex: 1 1 auto; display: flex; flex-direction: column; }

	&__text { flex: 1 0 auto; }

	&__title {
		margin-top: 10px; font-weight: $bold; font-size: 14px; line-height: 18px;
		.size { float: right; white-space: nowrap; margin: 0 0 0 10px; font-weight: 400; }
	}

	&__description {
		display: none;
		.meta {
			i { font-style: normal; opacity: 0.5; }
		}
	}

	&__meta {
		padding: 1px 0 0 0; margin: 10px 0 0 -20px; list-style: none; display: flex; flex-direction: row; flex-wrap: wrap; position: relative;
		&:before { content: ""; background-color: $color-gray-lightest; height: 1px; position: absolute; top: 0; left: 20px; right: 0; }
		> LI {
			flex: 0 0 auto; margin: 10px 0 0 20px;
			display: flex; flex-direction: row; flex-wrap: nowrap; line-height: $gridunit; font-size: 12px;
			.icon {
				flex: 0 0 auto; width: 14px; height: $gridunit; overflow: hidden; display: flex; align-items: center; justify-content: center;
				img, svg { width: 14px; height: 14px; margin: 0; display: block; }
			}
			.label { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; margin-left: 8px; }
		}
	}

	&__pre-title {
		color: $color05; font-size: 12px; line-height: 1.4; position: absolute; transform: translate(0, -50%);
	}

	&__prices {
		display: flex; align-items: flex-start; justify-content: space-between; flex: 0 0 auto; margin-top: 10px; border-top: 1px solid $color-gray-lightest;
		flex-wrap: wrap; min-height: 100px;

		&__content {
			flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; margin: 10px 10px 0 0;
			&.in-columns {
				display: flex; flex-wrap: wrap;
				@include mobile { flex-direction: column; }
				.box-product__price {
					flex: 1 1 50%; padding-right: $gridunit-small/2; box-sizing: border-box; white-space: nowrap;
					&,
					&.old { font-size: 20px; line-height: 1.5; }
					SPAN:not(.discount) { display: flex; font-size: $font-size-smaller; font-weight: 300; }
				}
			}
		}

		&__action {
			flex: 0 0 100%; height: 40px; border: none; padding: 0; margin: 10px 0 0; background-color: $color02; color: $color-white; position: relative; z-index: 5; cursor: pointer; font-weight: $bold; text-align: center;
			transition: $uispeed ease-in-out 0ms; transition-property: background-color, color, opacity, box-shadow, outline;
			img, svg { width: 20px; height: 20px; margin-right: $gridunit-small; }
			.holder { display: flex; justify-content: center; align-items: center; }
			path {
				fill: currentColor;
			}
			//.label { display: none; }
			&:active,
			&:focus,
			&:hover {
				opacity: 0.8;
				background-color: $color-link-hover; text-decoration: none; box-shadow: 0 0 0 3px rgba($color-link-hover, 0.5);
			}
			&[disabled] { pointer-events: none; background-color: $color01; opacity: 0.1; }
		}
	}

	&__price {
		font-weight: $bold; font-size: 20px; line-height: 24px;
		&.old { font-size: 13px; line-height: 16px; font-weight: 400; }
		&.sale { color: $color05; }
		.discount {
			font-size: 10px; line-height: 16px; background-color: $color01; color: $color-white; padding: 0 4px; margin: 0 0 0 10px; user-select: none; vertical-align: 20%;
			&.real { background-color: $color05; }
		}
	}

	&__details {
		background-color: rgba($color-white, 0.5); padding: 0 $gridunit $gridunit $gridunit; font-size: $font-size-smaller; line-height: 1.2;
		border: solid rgba($color-black, 0.05); border-width: 0 1px 1px 1px;
		@include mobile { display: none; }
	}

	&__attributes {
		margin: $gridunit * 0.75 0 0 0;
		> LI { display: flex; flex-direction: row; flex-wrap: nowrap; margin: $gridunit / 4; }

		@include desktop { column-count: 2; column-gap: $gridunit * 2; column-rule: 1px solid rgba($color-black, 0.05); }
		@include desktop-wide { column-count: 3; }

		&__label { margin: 0 $gridunit / 2 0 0; opacity: 0.5; }
		&__value {}
	}

	// On gray background

	@at-root .gray & {
		background-color: $color-white;
	}

	// Light

	@at-root .light & {
		background-color: transparent;
	}

	// List view

	@at-root .list & {
		flex-direction: row;
		flex-wrap: nowrap;
		text-align: left;
		align-items: center;
		margin: 12px 0 0 0;
		padding: $gridunit / 2 $gridunit;

		&__image { flex: 0 0 120px; max-width: 120px; height: 120px; padding: 0; margin-right: $gridunit; }
		&__description { display: block; font-size: 13px; margin-top: 10px; }
		&__pre-title { position: static; transform: none; }
		&__title { margin-top: 0; }
		&__prices {
			&__action {
				.holder { display: flex; align-items: center; justify-content: center; }
			}
		}

		@include not-desktop {
			display: block; position: relative;
			padding: $gridunit $gridunit $gridunit ($gridunit + $gridunit + 100px);
			&__meta {
				margin: 10px 0 0 -10px;
			}
			&__image { position: absolute; left: $gridunit; top: $gridunit; width: 100px; height: 100px; }
			&:hover { box-shadow: none; }
		}
		@include mobile {
			box-shadow: none; border-bottom: 1px solid $color-gray-lighter; margin: 0 $gridunit * -1; padding: $gridunit $gridunit $gridunit ($gridunit + 100px);
			&__image { left: 10px; }
			&__content { padding-left: 0; }
		}
		@include mobile-small {
			padding-left: 100px;
			&__image { width: 80px; height: 80px; }
		}
		@include desktop {
			&__meta {
				flex: 0 0 100px; padding: 0; border: none; margin: 0 0 0 $gridunit; display: block;
				&:before { display: none; }
				> LI {
					margin: 5px 0 0 0;
					&:first-child { margin: 0; }
				}
			}
			&__prices {
				flex: 0 0 160px; padding: 0; border: none; margin: 0 0 0 $gridunit; display: block;
				&__action {
					width: 100%; margin: 10px 0 0 0; padding: 0 20px 0 40px;
					.label { display: block; }
					img, svg { left: 20px; }
				}
			}
		}
	}
}

// Special search box

.box-search {
	@include defaultMargin();
	H2 { font-size: 12px; margin: 0; padding: 0; font-weight: 400; }

	@include mobile {
		margin: 0 $gridunit * -1;
	}

	&__tabs {
		display: flex; flex-direction: row; flex-wrap: nowrap; padding: 0; margin: 0 0 -1px -2px; list-style: none; position: relative; z-index: 1;
		text-transform: uppercase; font-weight: $bold; font-size: 12px;
		> LI {
			margin: 0 0 0 2px;
			BUTTON {
				cursor: pointer; border: none; appearance: none; border-radius: 0;
				position: relative; padding: $gridunit / 2 $gridunit; background-color: rgba($color-white, 0.5); box-shadow: $shadow04;
				&.active {
					color: $color-link; background-color: $color-white;
					&:before {
						content: ""; position: absolute; left: 1px; right: 1px; bottom: 0; height: 1px; background-color: $color-white;
					}
				}
				&:focus { text-decoration: underline; }
			}
			.holder { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }
			.icon {
				margin: 0 10px 0 -5px; flex: 0 0 auto; display: flex;
				IMG { width: $gridunit; height: $gridunit; }
			}

		}
		@include mobile {
			display: none;
		}
	}

	&__content {
		background-color: $color-white; position: relative; z-index: 0; box-sizing: border-box; box-shadow: $shadow04;
		display: flex; flex-direction: row; flex-wrap: wrap;
		padding: $gridunit / 2;
		@include not-desktop {
			display: block;
		}
		@include mobile {
			padding: 0; background-color: transparent; box-shadow: none;
		}
	}

	.column {
		padding: $gridunit / 2;
		&.license {
			@include desktop {
				flex: 0 0 auto;
			}
		}
		&.action {
			flex: 0 0 auto;
			@include mobile {
				.button { width: 100%; max-width: 100%; }
			}
		}
	}

	&__group-title {
		display: none;
		@include mobile {
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; cursor: pointer;
			text-transform: uppercase; font-weight: $bold; font-size: 12px;
			padding: $gridunit / 2 $gridunit; margin: 0; border-bottom: 1px solid $color-gray-lighter;
			.icon {
				margin-right: 10px; flex: 0 0 auto; display: flex;
				IMG { width: 16px; height: 16px; }
			}
			.label { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
			.arrow {
				margin-left: 10px; flex: 0 0 auto; display: flex;
				svg { width: 12px; height: 12px; }
				path { fill: $color-link; }
			}
			&.active {
				color: $color-link;
			}
			&:hover { color: $color-link; }
			& ~ *:not(&) { display: none !important; }
			&.active ~ *:not(&) {
				display: block !important;
			}
		}
	}

	&__group {
		flex: 1 1 auto; padding-top: $gridunit + 5;
		display: flex; flex-direction: row; flex-wrap: nowrap; position: relative;

		.column { flex: 1 1 25%; }

		&.license-search {
			flex: 0 0 auto; padding-right: $gridunit + 1;
			&:before { content: ""; position: absolute; right: $gridunit / 2; top: $gridunit / 2; bottom: $gridunit / 2; width: 1px; background-color: $color-gray-light; }
		}

		@include mobile {
			display: block; margin: $gridunit / 2;
			&:last-child { margin-bottom: $gridunit * 1.5; }
			&:after {
				content: ""; position: absolute; left: $gridunit / -2; right: $gridunit / -2; top: $gridunit / -2; bottom: $gridunit / -2; z-index: -1; pointer-events: none;
				background-color: $color-white; border-bottom: 1px solid rgba($color-black, 0.05);
			}
			&.license-search {
				display: flex; padding-right: 0;
				&:before { display: none; }
			}
		}
		@include tablet {
			&.license-search {
				display: flex; padding-right: 0;
				&:before { display: none; }
			}
		}
	}

	&__label {
		position: absolute; left: $gridunit / 2; top: $gridunit / 2;
		font-size: 12px; line-height: 16px;
		.#{$ns}-popover-wrapper { text-decoration: underline; text-decoration-style: dotted; cursor: help; }
	}

	&__fields {
		flex: 1 1 auto;
		display: flex; flex-direction: row; flex-wrap: nowrap;
		.column { flex: 1 1 25%; }
		@include mobile {
			display: block;
		}
		.control-holder {
			position: relative; height: $input-height;
			> * { position: absolute; left: 0; right: 0; top: 0; bottom: 0; }
		}
	}

	&__license {
		position: relative; width: 156px; font-weight: $bold; text-align: center;
		IMG { height: 38px; width: auto; overflow: hidden; }
		.control-input-addon-front { padding: 0 0 0 1px; }
		@include not-desktop {
			width: 100%;
		}
		&__results {
			margin: 5px 0 -5px 0; font-size: 12px; line-height: 20px;
			A {
				display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;
				svg { margin-left: 5px; }
				path { fill: currentColor; }
			}
		}
		&.control-input {
			box-shadow: inset 0 0 0 1px $color-black;
			&.focus { box-shadow: inset 0 0 0 1px $input-border-color-focus; background-color: $input-background-focus; }
		}
	}

	&__addon {
		background-color: rgba($color-white, 0.5); position: relative; z-index: 0; box-sizing: border-box; box-shadow: $shadow04; margin-top: -1px;
		@include defaultPadding; @include clearfix;
	}
}

// Product listing toolbar

.product-toolbar {
	$h: 30px;
	display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; font-size: $font-size-small; @include defaultMargin(); margin-bottom: -18px;

	@include mobile {
		margin: 0 $gridunit * -1; padding: 10px $gridunit; background-color: $color-gray-lightest; border-bottom: 1px solid $color-gray-lighter;
	}
	.group { flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }
	.label {
		display: none;
	}

	.icon {
		flex: 0 0 auto; display: block; width: 12px; height: 12px; overflow: hidden; margin: 0 7px 0 0; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
		IMG { width: 100%; height: auto; float: left; transform: translate(0, -50%); }
	}

	LABEL { flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }

	SELECT {
		border: 0 none; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); font-size: inherit; appearance: none; outline: none;
		padding: 0 30px 0 10px; height: $h; line-height: $h; border-radius: $base-radius; margin: 0 7px 0 0;
		background: $color-white url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
		&::-ms-expand {
			display: none;
		}
	}
	.togglebutton {
		display: flex; width: $h; height: $h; position: relative; z-index: 0;
		A {
			display: block; width: $h; height: $h; position: absolute; left: 0; top: 0; z-index: 0; opacity: 0;
			box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); border-radius: $base-radius;
			&.active { z-index: 2; opacity: 1; pointer-events: none; }
		}
		&:hover A {
			.icon { opacity: 1; }
		}
	}

	.pillbutton {
		position: relative; z-index: 0; display: flex; flex-direction: row; flex-wrap: nowrap;
		border-radius: $base-radius; overflow: hidden;
		&:before { content: ""; pointer-events: none; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); border-radius: $base-radius; z-index: 2; }
		A {
			flex: 0 0 auto; display: block; width: $h; height: $h; position: relative;
			&.active {
				background-color: $color-white;
			}
			&:not(:last-child) { border-right: 1px solid $color-gray-light; }
		}
	}
}

// Product filters

.product-filters {
	@include defaultMargin();
	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: flex-start; line-height: $gridunit;
	padding: $gridunit / 2 0 $gridunit 0; border: solid rgba($color-black, 0.1); border-width: 1px 0;

	H3 {
		margin: $gridunit / 4 $gridunit 0 0; font-size: inherit; line-height: $gridunit * 2; white-space: nowrap;
		display: flex; flex-direction: row; align-items: center;
		.icon { margin: 0 $gridunit / 2 0 0; }
		.arrow { display: none; margin: 0 0 0 $gridunit / 2; }
		svg { flex: 0 0 auto; }
		path { fill: currentColor; }
		> span { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
	}

	&__categories {
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start; justify-content: flex-start;
		margin-top: $gridunit / 4; margin-right: $gridunit / -4; white-space: nowrap;
		> LI {
			flex: 0 0 auto; min-width: 100px; margin: $gridunit / 4 $gridunit / 4 0 0; overflow: hidden; background-color: $color-white;
			&.reset { background-color: transparent; }
			.button {
				line-height: $gridunit-large; height: $gridunit-large;
				padding-left: $gridunit-large / 2;
				&.intent-default {
					box-shadow: inset 0 0 0 1px rgba($color-link, 0.2) !important; background-color: transparent;
					.icon,
					.text {
						color: $color-link;
					}
				}
			}
			@include mobile {
				flex: 1 1 50%;
				&.reset { margin-top: $gridunit; }
			}
		}
	}

	@include tablet { flex-direction: column; }

	@include mobile {
		display: block; border: none; padding: 0; margin: 0 $gridunit * -1;
		H3 {
			padding: 0 $gridunit; margin: 0; font-size: 12px; text-transform: uppercase; cursor: pointer; border-bottom: 1px solid rgba($color-black, 0.05);
			&:hover { color: $color-link; }
			.arrow { display: block; }
			svg { color: $color-link; }
		}
		&__categories {
			display: none; padding: $gridunit * 0.75 $gridunit * 0.75 $gridunit $gridunit; margin: 0; border-bottom: 1px solid rgba($color-black, 0.05); background-color: $color-white;
			&.open { display: block; }
		}
	}
}

// Accordions

.list-accordions {
	@include defaultMargin();
	> LI {
		overflow: hidden; position: relative; z-index: 0; background-color: $color-white; border: solid $color-gray-lighter; border-width: 1px 1px 0 1px;
		+ LI {
			margin: $gridunit / 2 0 0 0;
		}

		> INPUT[type="checkbox"] { display: none; }
		> INPUT[type="checkbox"] + * + * { transform: translateY(-100px); pointer-events: none; position: absolute; left: 0; top: 100%; width: 100%; z-index: -1; margin: 0; transition: all $uispeed $uieasing 0ms; display: block; }
		> INPUT[type="checkbox"]:checked ~ LABEL:before,
		> INPUT[type="checkbox"]:checked + * LABEL:before { transform: rotate(45deg); }
		> INPUT[type="checkbox"]:checked + LABEL ~ *,
		> INPUT[type="checkbox"]:checked + * ~ *, { display: block; transform: none; pointer-events: auto; position: static; z-index: 0; }

		> INPUT[type="checkbox"] + * {
			LABEL {
				&:before { content: "+"; position: absolute; right: $gridunit / 2; top: $gridunit * 0.75; width: $gridunit * 2; height: $gridunit * 2; font-size: $gridunit * 2; line-height: $gridunit * 2; text-align: center; transition: all $uispeed $uieasing 0ms; font-weight: 400; opacity: 0.4; }
				&:hover {
					&:before { opacity: 1; }
				}
			}
			@include mobile {
				LABEL {
					&:before { width: 30px; height: 30px; font-size: 30px; line-height: 30px; top: 15px; }
				}
			}
		}

		> {
			H2, H3, H4, H5, H6 {
				margin: 0; display: block; z-index: 2; position: relative; font-size: 20px; line-height: 30px; padding: 0; font-weight: $medium; background-color: $color-white; color: inherit;
				border-bottom: 1px solid $color-gray-lighter;
				A,
				LABEL {
					display: block; padding: $gridunit $gridunit * 3 $gridunit $gridunit; cursor: pointer; line-height: inherit; color: inherit;
					&:hover { color: $color-link; text-decoration: none; }
				}
				I { font-style: normal; font-weight: 300; }

				@include mobile {
					font-size: 16px; line-height: 30px;
					A, LABEL {
						padding: 15px 30px 15px 20px;
					}

				}
			}
		}
	}
	&__item {
		&__content {
			box-sizing: border-box; border-bottom: 1px solid $color-gray-lighter;
			@include defaultPadding;
		}
	}
}

// Longpage wrapper

.longpage { overflow: hidden; flex: 1 0 auto; }

// Utilities

.longtext {
	font-size: 16px; line-height: 1.47059;
	P, HR { @include defaultMargin(); }
	IMG { max-width: 100%; width: auto; height: auto; }
}

.cat-label { color: $color05; font-size: 11px; margin-left: 5px; vertical-align: 5%; }

.pre-title {
	color: $color05; font-size: 15px; line-height: 18px; display: block; font-weight: 400;
	@include mobile { font-size: 11px; }
}

// Tech specs

.tech-specs {
	display: flex; flex-direction: row; flex-wrap: nowrap; @include defaultMargin();
	& + & { border-top: 1px solid $color-gray-lighter; margin: 5px 0 0 0; padding-top: 5px; }

	P { margin: 0; }
	P + P { margin-top: 0.5em; }

	&__title {
		flex: 0 0 200px; font-weight: $medium; margin: 0 $gridunit 0 0;
		.price { display: block; font-weight: 400; font-size: 80%; }
	}
	&__description {
		flex: 1 1 auto;
	}

	@include mobile {
		display: block;
	}

	TABLE:not([class]) {
		margin-top: 15px;
	}
}

// Paginator

.paginator {
	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; font-size: 16px; color: $color-gray-medium; @include defaultMargin();
	INPUT {
		outline: none; width: 34px; height: 34px; border: none; box-shadow: inset 0 0 0 1px $color-gray-light; border-radius: $base-radius; text-align: center; color: $color-gray-medium; padding: 0; margin: 0 1ex 0 0; font-size: 16px; line-height: 34px; box-sizing: border-box;
		&:focus {
			box-shadow: inset 0 0 0 1px $color-gray-medium, 0 0 0 3px rgba($color-link, 0.2); color: $color-text-dark;
		}
	}
	&__counter {
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center; margin: 0 50px;
	}
	A {
		width: 36px; height: 36px; border-radius: $base-radius; overflow: hidden; display: block; transition: all $uispeed $uieasing 0ms; position: relative; color: inherit;
		&:hover { background-color: $color-gray-lightest; }
		SPAN { display: none; }
		&:before { content: ""; position: absolute; left: 50%; top: 50%; width: 14px; height: 14px; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform-origin: 50% 50%; }
	}
	&__prev:before { transform: translate(-40%, -50%) scaleX(0.85) rotate(-135deg) scale(0.8); }
	&__next:before { transform: translate(-60%, -50%) scaleX(0.85) rotate(45deg) scale(0.8); }
}

// Cart

.cart-table {
	width: 100%; box-sizing: border-box; margin: 0; border-collapse: collapse; border-spacing: 0;
	@include defaultMargin($gridunit);

	TH, TD {
		padding: 20px 10px; margin: 0; border: none; text-align: left; vertical-align: top; border-top: 1px solid $color-gray-light;
		@include not-mobile {
			&:first-child { padding-left: 0; }
			&:last-child { padding-right: 0; }
		}
	}

	TR:first-child {
		TH, TD { border-top: 0 none; }
	}

	P { margin: 0; }

	.sum { text-align: right; white-space: nowrap; }

	.image { display: block; width: 150px; height: 150px; background: $color-white 50% 50% no-repeat; background-size: contain; }

	&-image {
		A { display: block; }
	}

	&-title {
		font-weight: $bold; font-size: $font-size-large; line-height: 1.5;
		A {
			color: inherit;
			&:hover { color: $color-link; }
		}
	}

	&-config {
		margin: 20px 0 0 0; display: block; border-radius: $base-radius; overflow: hidden;
		> LI {
			padding: 0; margin: 0;
			& + LI { margin-top: -1px; }
			&:first-child LABEL SPAN { border-radius: $base-radius $base-radius 0 0; }
			&:last-child LABEL SPAN { border-radius: 0 0 $base-radius $base-radius; }
		}
		LABEL {
			display: block; overflow: hidden;
			INPUT {
				position: absolute; right: 100%; bottom: 100%;
				&:checked + SPAN {
					z-index: 2;
					&:before {
						content: ""; width: 6px; height: 10px; position: absolute; left: 9px; top: 9px; box-sizing: border-box; border: solid $color-link; border-width: 0 2px 2px 0;
						transform: translate(-40%, -50%) rotate(45deg);
					}
					&:after { box-shadow: inset 0 0 0 1px $color-link; }
				}
			}
			SPAN {
				flex: 1 1 auto; display: inline-block; overflow: hidden; padding: 3px 0 3px 30px; cursor: pointer; position: relative; z-index: 0;
				&:hover { color: $color-link; }
				&:after { content: ""; width: 20px; height: 20px; background-color: $color-white; border-radius: 50%; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); position: absolute; left: 0; top: 0; z-index: -1; }
			}
		}
	}

	TR:not(.cart-table-subproduct) + TR:not(.cart-table-subproduct) {
		TH, TD { border-top: 1px solid $color-gray-light; }
	}

	&-subproduct {
		TH, TD { border-top: 1px solid $color-gray-lightest; }
	}
	&-subproduct &-title { font-size: $font-size-medium; }

	&-specs {
		margin: 10px auto 0 0;
		> LI {
			display: flex; flex-direction: row; flex-wrap: nowrap;
			.label { flex: 0 0 120px; opacity: 0.5; }
			.value { flex: 1 1 auto; }
		}
	}

	&-price {
		white-space: nowrap; background-color: rgba($color-black, 0.03); padding: 15px 20px; border-radius: $base-radius; overflow: hidden; font-size: $font-size-medium;
		> LI {
			+ LI { margin-top: 5px; }
			display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between;
			.label { flex: 0 0 auto; padding-right: 20px; }
			.value {
				flex: 0 0 auto;
				display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;justify-content: flex-start;
				.number { flex: 0 0 auto; text-align: center; padding: 0 10px; }
				&-button {
					flex: 0 0 auto; display: block; box-shadow: inset 0 0 0 1px $color-gray-light; width: 20px; height: 20px; box-sizing: border-box; text-align: center; line-height: 20px; color: inherit; background-color: $color-white; font-size: 12px; font-weight: $bold;
					&:hover { text-decoration: none; color: $color-link; box-sizing: inset 0 0 0 1px $color-link; box-shadow: inset 0 0 0 1px $color-link; }
				}
			}
			&.total { font-weight: $bold; }
		}
	}

	&-remove { margin-top: 10px !important; font-size: $font-size-small; text-align: center; }
	.sum { white-space: nowrap; text-align: right; }

	@include mobile {
		margin-left: $gridunit * -1; margin-right: $gridunit * -1; width: 100vw; margin-top: $gridunit;
		TR { display: flex; flex-direction: column; border-top: 1px solid rgba(0, 0, 0, 0.05); }
		TH, TD {
			padding: 20px 20px 0 20px; border: none !important;
			&:last-child { padding-bottom: 20px; }
		}
		&-image { display: flex; align-items: center; justify-content: center; }
		.image { width: 100px; height: 100px; }
	}

	.cart-callout { margin-top: 8px !important; padding: 10px 20px; }
}

.cart-block {
	padding: $base-padding; @include defaultMargin(); border-radius: $base-radius; overflow: hidden; box-shadow: $shadow04;
	&.primary { background-color: rgba($color-black, 0.05); box-shadow: none; }
	H2 { font-size: $font-size-medium; margin: $base-margin; }
	.cart-button { padding: 0 $gridunit; }
}

.cart-prices {
	margin: $base-margin;
	> LI { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: space-between; }
	.primary { font-weight: $bold; font-size: 18px; margin-top: $gridunit; }
	.label { flex: 1 1 auto; }
	.value { flex: 0 0 auto; padding-left: 8px; }
}

.cart-buttons {
	@include defaultMargin(8px);
	> LI {
		margin: 8px 0 0 0;
	}
}

.cart-button {
	font-weight: 400; font-size: 18px; background-color: $color-white; border: none; outline: none; color: $color-link; padding: 0 50px; text-align: center; line-height: 40px; cursor: pointer; border-radius: $base-radius; margin: 0; display: block; width: 100%; box-sizing: border-box; white-space: nowrap; box-shadow: inset 0 0 0 1px $color-link;
	&.primary {
		background-color: $color-link; color: $color-white; box-shadow: none;
		&:hover { background-color: $color-link-hover; text-decoration: none; }
	}
}

.cart-callout {
	padding: $gridunit $gridunit; border-radius: $base-radius; text-align: center; font-weight: $medium; background-color: rgba($color-black, 0.05);
	@include defaultMargin();
	& + & { margin-top: 8px !important; }

	&.intent-danger { color: $color-danger; background-color: rgba($color-danger, 0.1); }
	&.intent-warning { color: $color-warning; background-color: rgba($color-warning, 0.1); }
	&.intent-success { color: $color-success; background-color: rgba($color-success, 0.1); }
	&.intent-promotion { color: $color-link; background-color: rgba($color-link, 0.1); }
}

// Generic callout

.generic-callout {
	padding: 0 $spacer-desktop; position: relative; color: $color-gray-dark;

	@include mobile { padding: 0 $spacer-mobile; }
	@include tablet { padding: 0 $spacer-tablet; }

	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit / 2 0; font-size: $font-size-small;
		P { margin: 0; }
	}

	background-color: $color-gray-lighter;

	&.intent-danger {
		color: $color-white; background-color: $color-danger;
		A { color: $color-white; text-decoration: underline; }
	}
	&.intent-warning {
		color: $color-black; background-color: $color-warning;
		A { color: $color-link; text-decoration: underline; }
	}
	&.intent-success {
		color: $color-white; background-color: $color-success;
		A { color: $color-white; text-decoration: underline; }
	}
	&.intent-promotion {
		color: $color-white; background-color: $color-link;
		A { color: $color-white; text-decoration: underline; }
	}

	& + & {
		&:before { content: ""; position: absolute; left: 0; top: 0; right: 0; height: 1px; background-color: currentColor; opacity: 0.1; }
	}
}

// Generic message

.generic-message {
	flex: 1 0 auto; text-align: center; font-size: $font-size-medium; line-height: 1.5;
	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit-large;
		P { margin: 0; }
	}
	&__title { font-size: $font-size-large; font-weight: $bold; }
}

// Footnote

.footnote {
	font-size: $font-size-smaller; color: $color-gray-medium; border-top: 1px solid $color-gray-lighter; line-height: 1.5; margin: 50px 0 0 0;
}

// Generic action links

.generic-actions {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 10px -12px 0 -12px;
	> * {
		margin: 10px 12px; position: relative;
		&:not(:first-child):before { content: "|"; position: absolute; left: -12px; top: 50%; color: $color-gray-light; pointer-events: none; transform: translate(-50%, -50%); }
	}
	.intent-danger { color: $color-danger; }
}

// Icons list

.list-icons {
	display: flex; flex-direction: row; flex-wrap: wrap; text-align: center; margin: 8px -6px 0 -6px;
	> LI {
		flex: 0 0 20%; margin: 0; padding: 12px 6px 0 6px; box-sizing: border-box; overflow: hidden; display: flex; flex-direction: column;
		@include mobile {
			flex: 1 1 50%;
		}
		@include tablet {
			flex: 1 1 33.333%;
		}
	}
	A {
		flex: 1 0 auto; display: block; color: inherit; text-decoration: none; background-color: $color-white; box-shadow: $shadow04;
		padding: $gridunit; overflow: hidden; text-overflow: ellipsis;
		&:hover {
			box-shadow: inset 0 0 0 1px rgba($color-black, 0.1);
			.more { color: $color-link-hover; text-decoration: underline; }
		}
	}
	H2, H3, H4, H5, H6 { font-size: inherit; font-weight: $bold; line-height: inherit; margin: 0; }
	P { margin: 5px 0 0 0; }

	.icon { width: 100px; height: 40px; background-position: 50% 50%; background-size: contain; background-repeat: no-repeat; margin: 0 auto 10px auto; }
	.more {
		color: $color-link; position: relative; display: inline-block; padding: 0 20px;
		&:hover { color: $color-link-hover; }
		&:after { content: ""; position: absolute; left: 100%; top: 50%; width: 0.5em; height: 0.5em; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform: translate(-150%, -50%) scaleX(0.85) rotate(45deg) scale(0.7); transform-origin: 50% 50%; }
	}

	UL:not([class]) {
		padding: 0; margin: $gridunit 0 0 0;
		> LI {
			padding: 0; margin: 0;
			&:before { display: none; }
			+ LI {
				padding-top: 20px;
				&:before {
					display: block; width: 10px; height: 1px; left: 50%; top: 10px; border-radius: 0; transform: translate(-50%, 0); opacity: 0.2;
				}
			}
		}
	}
}

// Cookie message

.cookiemessage {
	background-color: $color-white; color: $color-gray-dark;
	position: sticky; bottom: 0; padding: 0 $spacer-desktop; z-index: 16000003; // zopim + 1
	&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: 0 0 10px 0 rgba($color-black, 0.1); pointer-events: none; }

	@include mobile { padding: 0 $spacer-mobile; }
	@include tablet { padding: 0 $spacer-tablet; }

	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit / 2 0 $gridunit 0; text-align: center; font-size: $font-size-small;
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start; justify-content: space-between;
		@include mobile { display: block; }
	}

	.content { flex: 0 1 auto; line-height: 20px; margin: $gridunit / 2 0 0 0; }
	.actions {
		margin: $gridunit / 4 0 0 0; flex: 0 1 auto; display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;
		A {
			flex: 0 0 auto; background-color: $color-link; color: $color-white; line-height: 20px; padding: 0 10px; margin: $gridunit / 4 3px 0 3px; font-size: $font-size-smaller;
			transition: all $uispeed ease-in-out 0ms;
			&:active,
			&:focus,
			&:hover { text-decoration: none; background-color: $color-link-hover; box-shadow: 0 0 0 3px rgba($color-link-hover, 0.3); }
		}
	}
}

// Embed video

.embed-video {
	@include defaultMargin();
	position: relative; padding-bottom: 56.25%; height: 0;
	IFRAME {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Well for text

.box-well {
	background-color: rgba($color-white, 0.5); @include defaultPadding; @include defaultMargin(); @include clearfix; box-shadow: inset 0 0 0 1px $color-gray-lighter;
}

// Category landing box

.box-category {
	@include not-mobile {
		background-color: $color-white; @include defaultPadding; @include defaultMargin(); box-shadow: inset 0 0 0 1px $color-gray-lighter; @include clearfix;
	}
	&__heading {
		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;
		margin: $gridunit 0 0 0;
		H1, H2, H3 { font-size: 24px; flex: 1 1 auto; margin: 0; }
		P { flex: 0 0 auto; margin: 0 0 0 $gridunit; }
		@include mobile {
			padding-bottom: $gridunit;
			H1, H2, H3 { font-size: 18px; }
		}
	}

	.layout-products {
		&__list {
			@include mobile { border-top: 1px solid $color-gray-light; }
		}
		@include desktop {
			&__container {
				flex: 0 0 50% !important; max-width: 50% !important;
			}
		}
		@include desktop-wide {
			&__container {
				flex: 0 0 33.333% !important; max-width: 33.333% !important;
				&:nth-child(3) ~ * { display: none; }
			}
		}
	}
}

// Sitemap tree

.sitemap {
	padding: 0; @include defaultMargin();
	UL { margin: 10px 0 0 0; padding: 0; }
	> LI {
		background-color: $color-white; padding: $gridunit; box-shadow: $shadow04;
		+ LI { margin-top: 5px; }
		> A { font-weight: $bold; font-size: 18px; }
		UL.grouped {
			margin: 0; padding: 0;
			> LI {
				border-top: 1px solid rgba($color-black, 0.05); margin: 10px 0 0 0;
				> A { display: block; font-weight: $bold; margin: 10px 0 0 0; }
			}
		}
	}
}

// Blog articles list

.list-articles {
	padding: 0; line-height: 1.5; @include defaultMargin($gridunit / 2); margin-left: $gridunit / -4; margin-right: $gridunit / -4;
	display: flex; flex-direction: row; flex-wrap: wrap;
	> LI {
		box-sizing: border-box; padding: $gridunit / 2 $gridunit / 4 0 $gridunit / 4; margin: 0;
		display: flex; flex-direction: column;
		@include mobile {
			flex: 0 0 100%; max-width: 100%;
		}
		@include tablet {
			flex: 0 0 50%; max-width: 50%;
		}
		@include desktop {
			flex: 0 0 33.3333%; max-width: 33.3333%;
		}
	}
	A {
		display: flex; flex-direction: column; color: currentColor; text-decoration: none; flex: 1 0 auto;
		background-color: $color-white; padding: 0; box-shadow: $shadow04;
		&:hover { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); }
	}
	A:hover &__headline { color: $color-link-hover; }

	&__image { margin: 1px 1px 0 1px; padding-top: 65%; background-color: rgba($color-black, 0.02); background-size: cover; background-position: 50% 0; background-repeat: no-repeat; }
	&__headline { padding: $gridunit; font-weight: $bold; }
	&__lead { font-size: $font-size-small; padding: 0 $gridunit $gridunit $gridunit; }
}

// Newsletter ordening

.box-newsletter {
	.form-list {
		font-size: $font-size-small;
		> LI > LABEL {
			font-weight: 400;
		}
	}
}

// Popular categories list

.popular-categories {
	margin: $gridunit / 2 $gridunit / -2 0 0; font-weight: $bold; font-size: $font-size-small;
	display: flex; flex-direction: row; flex-wrap: wrap; text-align: center;
	> LI {
		flex: 0 0 16.666%; padding: $gridunit / 2 $gridunit / 2 0 0; box-sizing: border-box; overflow: hidden;
		@include mobile { flex: 0 0 50%; font-size: $font-size-smaller; }
		@include tablet { flex: 0 0 33.333%; font-size: $font-size-smaller; }
		@include desktop {}
		A {
			display: block; padding: $gridunit / 2; background-color: $color-white; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); color: inherit;
			white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
		}
	}
}
