@import "mixins";
@import "variables";

A, BUTTON {
	color: $color-link; text-decoration: none;
	&:hover { text-decoration: underline; }
}

BODY, HTML { background: $color-white; position: relative; z-index: 0; box-sizing: border-box; height: 100%; }
HTML { -ms-overflow-style: scrollbar; color: $color-text-dark; overflow: auto; overflow-x: hidden; overflow-y: scroll; }
.epharmacy-root, .ecommerce-root { position: relative; min-height: 100%; }

@include not-mobile {
	BODY, HTML { height: 100vh; min-height: -webkit-fill-available; }
	.epharmacy-root, .ecommerce-root { min-height: 100vh; min-height: -webkit-fill-available; }
}

@media print {
	BODY, HTML { background: $color-white; padding: 0; margin: 0; }
	* {
		background: transparent !important; color: inherit !important; border-color: currentColor !important;
		&:before, &:after { border-color: currentColor !important; }
	}
}

OL:not([class]) {
	counter-reset: li; padding: $gridunit * 0.5 0 0 0; margin: 0; list-style: none;
	LI:not([style]):not([class]) {
		display: block; padding: 0 0 0 $gridunit * 2.5; margin: $gridunit / 2 0 0 0; position: relative;
		&:before { content: counter(li) "."; counter-increment: li; color: currentColor; position: absolute; left: 0; top: 0; width: $gridunit * 2; text-align: right; }
	}
}

UL { padding: 0; margin: 0; list-style: none; }

UL:not([class]) {
	padding: $gridunit 0 0 0;
	LI {
		display: block; padding: 0 0 0 $gridunit * 2.5; margin: 0; position: relative;
		&:before { content: ""; background: $color02; position: absolute; left: $gridunit * 1.5; top: 0.65em; width: 5px; height: 5px; }
	}
}

MARK { display: inline; background-color: rgba($color-black, 0.1); color: inherit; }

// Generic table

DIV.table {
	width: 100%; overflow: hidden; overflow-x: auto; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto;
}

TABLE:not([class]) {
	font-size: $base-font-size; line-height: $base-line-height;
	width: 100%; box-sizing: border-box; border-collapse: collapse; border-spacing: 0;
	background-color: $color-white; border: 1px solid $color-gray-lighter;
	@include defaultMargin();
	TH, TD {
		padding: $gridunit / 2; border: solid $color-gray-lighter; border-width: 1px 0;
		&:first-child { padding-left: $gridunit; }
		&:last-child { padding-right: $gridunit; }
	}
	TH { text-align: left; vertical-align: top; font-weight: $bold; font-size: $font-size-small; }
	TD {
		white-space: nowrap;
	}
	THEAD {
		TR:not(:first-child) {
			TH { font-size: $font-size-smaller; }
		}
	}
	.sum { white-space: nowrap; text-align: right; }
}
