@import "mixins";
@import "variables";

// Product image

.product-image {
	@include defaultMargin();
}

// Product configurator

.product-configurator {
	&__title {
		font-weight: $bold; margin: 20px 0 0 0;
	}
	&__group {
		display: flex; flex-direction: row; flex-wrap: nowrap; margin: 5px -5px 0 0;
		> LI { margin: 5px 5px 0 0; }
	}

	LABEL {
		display: flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden; cursor: pointer; font-size: 14px; line-height: 16px;
		INPUT {
			position: absolute; right: 100%; bottom: 100%;
			&:checked + .label { box-shadow: inset 0 0 0 2px $color-link; }
			&:checked + .swatch { box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color-link; }
		}
		.label {
			flex: 1 1 auto; display: block; overflow: hidden; padding: 10px 15px; box-shadow: inset 0 0 0 1px $color-gray-light; border-radius: $base-radius;
			&:hover { box-shadow: inset 0 0 0 1px $color-gray-medium; }
		}
		.swatch {
			margin: 4px; display: block; width: 24px; height: 24px; overflow: hidden; border-radius: 50%; box-shadow: 0 0 0 3px $color-white, 0 0 0 4px rgba($color-black, 0.07); position: relative;
			&:hover { box-shadow: 0 0 0 3px $color-white, 0 0 0 4px rgba($color-black, 0.15); }
			&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: linear-gradient(135deg, rgba($color-black, 0), rgba($color-black, 0.03)); }
		}
	}
}

// Product pricing

.product-pricing {
	flex: 0 0 auto;
	background-color: $color-gray-lighter; @include defaultMargin(); padding: $gridunit / 2 $gridunit $gridunit $gridunit; border-radius: $base-radius; @include clearfix;
	P { margin-top: $gridunit / 2; }
	&__prices {
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-end; padding: 0; margin-top: 0; margin-right: $gridunit * -1; position: relative;
		> LI {
			padding: $gridunit / 2 $gridunit 0 0; box-sizing: border-box; flex: 1 1 50%;

			.label { display: flex; align-items: center; }
			.price {
				font-size: 24px; line-height: 24px; font-weight: 300;
				&.old { font-weight: 300 !important; opacity: 0.5; text-decoration: line-through; margin-top: 5px; }
				&.sale { color: $color05; }
				&[class="price"] { font-weight: 300 !important; opacity: 0.5; }
				+ .label { margin-top: $gridunit-small; }
			}

			&.primary {
				.label {}
				.price { font-weight: $bold; }
			}
			&.actions {
				display: block;
				flex: 1 0 250px
			}
		}
		& + & {
			margin-top: ($gridunit / 2) + 1;
			&:before { content: ""; position: absolute; left: 0; right: $gridunit; top: 0; height: 1px; background-color: $color-gray-light; }
		}
	}

	.discount {
		font-size: 12px; line-height: 20px; background-color: $color01; color: $color-white; padding: 0 4px; margin: 0 0 0 10px; user-select: none; vertical-align: 20%;
		&.real { background-color: $color05; }
	}

	&__button {
		font-weight: 400; font-size: 18px; background-color: $color-link; border: none; outline: none; color: $color-white; padding: 0 50px; text-align: center; line-height: 40px; cursor: pointer; border-radius: $base-radius; margin: 0; width: 100%; box-sizing: border-box; white-space: nowrap;
		transition: all $uispeed ease-in-out 0ms;
		&:active,
		&:focus,
		&:hover {
			background-color: $color-link-hover; text-decoration: none; box-shadow: 0 0 0 3px rgba($color-link-hover, 0.3);
		}

		&.secondary { background-color: $color05; }

		&[disabled],
		&.disabled { opacity: 0.5; pointer-events: none; }

		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center;

		img { width: 20px; height: 20px; flex: 0 0 auto; margin: 0 10px 0 0; }
		.label { flex: 0 0 auto; }
	}
}

// Availability

.product-availability {
	font-size: 12px; @include defaultMargin();
	@include not-mobile {
		column-count: 2;
	}
	> LI {
		display: block; margin: 0; padding: 0 0 0 20px; position: relative; z-index: 0;
		+ LI { margin-top: 0; }
		&:before { content: ""; position: absolute; left: 0; top: 6px; width: 10px; height: 10px; background-color: $color-gray-lightest; border-radius: 50%; }
		&.available {
			&:before { background-color: $color-success; }
			.status { color: $color-success; }
		}
		&.unavailable {
			&:before { background-color: $color-danger; }
			.status { color: $color-danger; }
		}
		&.warning {
			&:before { background-color: $color-warning; }
			.status { color: $color08; }
		}
	}
	A {
		color: inherit;
		&:hover { text-decoration: none; color: $color-link; }
	}
}

// Financing calculator

.financing-calculator {
	padding: 0 0 $gridunit $gridunit; border-radius: $base-radius; background-color: $color-gray-lightest;
	display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;

	&__form {
		flex: 1 0 230px; padding: 15px $gridunit 0 0;
		&__row {
			$h: 30px;
			display: flex; flex-direction: row; flex-wrap: nowrap; margin: 5px 0 0 0;
			LABEL { line-height: $gridunit; padding: 5px 20px 5px 0; flex: 0 0 100px; box-sizing: border-box; font-size: 12px; }
			INPUT, SELECT {
				border: 0 none; box-shadow: none; font-size: inherit; appearance: none; outline: none;
				margin: 0; width: 100%; background-color: rgba($color-black, 0.05); height: $h; line-height: $h; border-radius: $base-radius;
				&:focus { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
			}
			INPUT {
				padding: 0 10px;
				&:not(:last-child) { padding-right: 50px; }
			}
			SELECT {
				color: $color-text-dark; padding: 0 30px 0 10px; background: rgba($color-black, 0.05) url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
				&::-ms-expand {
					display: none;
				}
			}
			.fields { flex: 1 1 auto; display: flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden; box-shadow: inset; position: relative; }
			.addon { position: absolute; right: 0; top: 0; padding: 0 10px; line-height: $h; pointer-events: none; font-size: 12px; opacity: 0.6; }

			@include mobile {
				display: block;
				LABEL { padding: 0 0 5px 0; }
			}
		}
	}
	&__result {
		flex: 0 0 200px; padding: $gridunit $gridunit 0 0;
		.result-label {}
		.result-sum { font-size: 24px; line-height: 30px; font-weight: $bold; }
		.result-disclaimer { font-size: 11px; line-height: 1.5; color: $color-gray-dark; margin: 10px 0 0 0; max-width: 200px; }
	}
}

//

.product-columns {
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: nowrap; margin: 0 $gridunit * -1;
		&__column {
			flex: 0 1 50%; padding: 0 $gridunit;
		}
	}
}
