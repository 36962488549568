// Units

$gridunit: 20px;
$gridunit-small: 10px;
$gridunit-large: 30px;
$uispeed: 250ms;
$uieasing: cubic-bezier(0.42, 0, 0.58, 1);
$base-radius: 0;
$content-maxwidth: 1200px;

$spacer-desktop: 30px;
$spacer-desktop-vertical: 30px;
$spacer-tablet: 30px;
$spacer-tablet-vertical: 30px;
$spacer-mobile: 20px;
$spacer-mobile-vertical: 20px;

$grid-gap: 24px;
$column-width: 78px;

// Colors
$color01: #444;
$color02: #007FC2;
$color03: #e1eaef;
$color04: #f3f6fa;
$color05: #E12E27;
$color06: #0077ED;
$color07: #2997ff;
$color08: #ea931e;
$color09: #ffe000;

$color-white: #fff;
$color-black: #000;
$color-link: $color02;
$color-link-hover: lighten($color-link, 5);
$color-disabled: rgba($color-black, 0.5);
$color-text-medium: lighten($color-black, 30);
$color-text-dark: $color-black;
$color-text-light: $color-white;

$color-gray-lightest: #fafafa;
$color-gray-lighter: #ededed;
$color-gray-light: #dbdbdb;
$color-gray-medium: #aaa;
$color-gray-dark: #888;
$color-gray-darker: #666;
$color-gray-darkest: #444;

$color-primary: $color02;
$color-success: #109200;
$color-warning: $color09;
$color-danger: $color05;
$color-disabled: $color-gray-medium;
$color-active: $color-link;

// Sizes

$mobile-header-height: 50px;
$comment-width: 280px;

$input-height: 40px;
$input-background: $color-white;
$input-background-focus: $color-white;
$input-border-color: rgba($color-black, 0.3);
$input-border-color-hover: rgba($color-black, 0.35);
$input-border-color-focus: rgba($color-black, 0.40);

$button-default-height: $input-height;
$button-small-height: $gridunit;

$base-padding: 0 $gridunit $gridunit $gridunit;
$base-padding-large: 0 $gridunit-large $gridunit-large $gridunit-large;

$base-padding-mobile: 0 $spacer-mobile $spacer-mobile-vertical $spacer-mobile;
$base-padding-tablet: 0 $spacer-tablet $spacer-tablet-vertical $spacer-tablet;
$base-padding-desktop: 0 $spacer-desktop $spacer-desktop-vertical $spacer-desktop;

$base-margin: $gridunit 0 0 0;
$base-margin-large: $gridunit-large 0 0 0;

$base-font-size: 14px;
$font-size-smallest: 10px;
$font-size-smaller: 12px;
$font-size-small: 13px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-larger: 24px;
$font-size-largest: 28px;

$base-line-height: 22px;
$line-height-small: 16px;
$line-height-medium: 26px;
$line-height-large: 30px;

$shadow01: 0 10px 15px -5px rgba($color-black, 0.2);
$shadow02: 0 $gridunit / 2 $gridunit $gridunit / -2 $color-black;
$shadow03: 0 $gridunit / 2 $gridunit / 2 $gridunit / -2 rgba($color-black, 0.2);
$shadow04: inset 0 0 0 1px rgba($color-black, 0.05);
$shadow-menus: 0 0 0 1px $color-gray-light, 0 $gridunit / 4 $gridunit / 2 $gridunit / -4 $color-black;

$medium: 700;
$bold: 700;

$intents: (
		"primary": $color-link,
		"success": $color-success,
		"warning": $color-warning,
		"danger": $color-danger,
		"active": $color-active,
);
