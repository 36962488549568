@import "mixins";
@import "variables";

// Browse layout

.layout-browse {
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: nowrap;
		&__sidebar { flex: 0 0 280px; overflow: hidden; padding-right: $gridunit; }
		&__main { flex: 1 1 auto; }
	}
	@include tablet {
		&__sidebar { padding-right: $spacer-tablet; }
	}
	@include desktop {
		&__sidebar { padding-right: $spacer-desktop; }
	}
}

// Grid layout for promotions

.layout-grid {
	flex: 1 0 auto;
	@include mobile {
		margin: 0 -20px;
		&__container { padding: 12px 0 0 0; }
	}
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: wrap; padding: 0 0 0 12px; margin: 0 -12px 0 -12px;
		&__container {
			flex: 0 0 50%; box-sizing: border-box; padding: 12px 12px 0 0; display: flex; flex-direction: column;
			&.wide { flex: 0 0 100%; }
		}
	}
	@include defaultMargin(12px);
}

// Products layout

.layout-products {
	&__list {
		flex: 1 0 auto; @include defaultMargin(12px);

		display: flex; flex-direction: row; flex-wrap: wrap;
		@include mobile { margin: 0 -20px 0 -21px; }
		@include not-mobile { padding: 0 0 0 12px; margin-left: -12px; margin-right: -12px; }

		&.light {
			display: flex; flex-direction: row; flex-wrap: wrap;

			@include mobile {
				margin: 0 -20px 0 -21px;
				.box-product { padding: 15px; font-size: 14px; }
			}
			@include not-mobile {
				margin: 0; padding: 0; overflow: hidden;
			}
		}

		&.list {
			display: block;
			margin-left: 0; margin-right: 0; padding: 0;
		}
	}

	&__container {
		display: flex; flex-direction: column; box-sizing: border-box;

		@include mobile { flex: 0 0 100%; max-width: 100%;  }
		@include mobile-modern { flex: 0 0 50%; max-width: 50%; }
		@include not-mobile {
			flex: 0 0 25%; max-width: 25%; padding: 12px 12px 0 0;
			@at-root .layout-browse & {
				flex: 0 0 33.333%; max-width: 33.333%;
			}
		}
		@include tablet {
			flex: 0 0 33.333%; max-width: 33.333%;
			@at-root .layout-browse & {
				flex: 0 0 50%; max-width: 50%;
			}
		}
	}

	&__list.light &__container {
		box-sizing: border-box; padding: 0;
		@include mobile {
			flex: 0 0 50%; max-width: 50%; padding: 0;
		}
		@include not-desktop-wide {
			flex: 0 0 50%; max-width: 50%;
		}
	}

	&__list.list &__container { max-width: 100%; padding: 0; }

	H1 + &,
	H2 + &,
	H3 + & {
		@include defaultMargin();
	}
}

// Single product layout

.layout-product {
	@include desktop {
		display: flex; flex-direction: row; flex-wrap: nowrap;
		margin: 0 $gridunit * -1;

		&__image { font-size: 12px; line-height: 1.5; position: relative; background-color: $color-white; @include defaultMargin(); border: 1px solid rgba($color-black, 0.05); padding-bottom: 10px; }
		&__column { flex: 0 1 50%; margin: 0 $gridunit; }
	}

	H1 { font-size: 32px; }

	&__image {
		position: relative;
		.product-sale { top: $gridunit-large; transform: scale(1.5); }
	}
	&__code {
		opacity: 0.5; font-size: $font-size-small;
		@include not-mobile { margin: 0; }
	}
}

// Login layout

.layout-login {
	flex: 1 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; margin: 0 -6px;
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: nowrap;
	}
	&__column {
		flex: 1 1 50%; box-sizing: border-box; background-color: $color-white; box-shadow: $shadow04; @include defaultPadding; @include defaultMargin(); margin-left: 6px; margin-right: 6px; border-radius: $base-radius;
		H2 { @include defaultMargin(); }
		H3 { border-bottom: 1px solid $color-gray-light; padding-bottom: 5px; margin-top: $gridunit-large; }
	}
}

// Stores layout

.layout-stores {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 0 $gridunit / -2; text-align: center;

	> LI {
		flex: 0 0 33.333%; max-width: 33.333%; box-sizing: border-box; padding: $gridunit $gridunit / 2 0 $gridunit / 2; position: relative;
		@include not-desktop { flex: 0 0 50%; max-width: 50%; }
		@include mobile { flex: 0 0 100%; max-width: 100%; }
		@include mobile-wide { flex: 0 0 50%; max-width: 50%; }
	}
	H1, H2, H3, H4, H5 { margin-top: $gridunit; font-size: 18px; }
	P { margin: 5px 0 0 0; }
	A {
		display: block; color: inherit;
		&:hover { text-decoration: none; color: $color-link; }
	}
}

// Contacts layout

.layout-contacts {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 0 $gridunit / -2; text-align: center;

	> LI {
		flex: 0 0 33.333%; max-width: 33.333%; box-sizing: border-box; padding: $gridunit $gridunit / 2 0 $gridunit / 2; position: relative;
		@include not-desktop { flex: 0 0 50%; max-width: 50%; }
		@include mobile { flex: 0 0 100%; max-width: 100%; }
		@include mobile-wide { flex: 0 0 50%; max-width: 50%; }
	}
	H1, H2, H3, H4, H5 { margin-top: $gridunit; font-size: 18px; }
	P {
		margin: 10px 0 0 0;
		&.description { color: $color-gray-medium; margin: 0 0 $gridunit / 2 0; }
	}
	A {

	}
	.image {
		display: block; background: 50% 50% no-repeat; background-size: contain; margin: 0 auto; height: 150px;
		@include mobile-wide { width: 150px; height: 150px; }
	}
	.logo {
		display: block; background: 50% 50% no-repeat; background-size: contain; margin: 0 auto; width: 200px; height: 100px;
		@include mobile-wide { width: 200px; height: 100px; }
	}
}

// Services layout

.layout-services {
	display: flex; flex-direction: row; flex-wrap: wrap; text-align: center;
	@include defaultMargin(12px);
	@include mobile { margin: 0; }
	@include not-mobile { display: flex; flex-direction: row; flex-wrap: wrap; padding: 0 0 0 12px; margin-left: -12px; margin-right: -12px; }

	> LI {
		flex: 0 0 33.333%; max-width: 33.333%; box-sizing: border-box; padding: 12px 6px 0 6px; position: relative;
		display: flex; flex-direction: column;
		@include not-desktop { flex: 0 0 50%; max-width: 50%; }
		@include mobile { flex: 0 0 100%; max-width: 100%; }
		@include mobile-wide { flex: 0 0 50%; max-width: 50%; }
	}
	A {
		flex: 1 0 auto; display: block; color: currentColor; background-color: $color-white; padding: 15px; font-weight: $bold;
		&:hover { text-decoration: none; color: $color-link; }
	}
	.frame-automaailm__illustration { margin: -15px -15px 15px -15px; }
}

// Shopping cart

.layout-cart {
	@include desktop {
		display: flex; flex-direction: row-reverse; flex-wrap: nowrap;
		&__sidebar { flex: 0 0 280px; overflow: hidden; padding-left: 40px; }
		&__main { flex: 1 1 auto; }
	}
}

// Generic address columns

.layout-addresses {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 0 -8px;
	> LI {
		flex: 0 0 33.333%; max-width: 33.333%; padding: 0 8px; box-sizing: border-box;
		@include not-desktop { flex: 0 0 50%; max-width: 50%; }
	}
	P + P { margin-top: 10px; }
}

//
